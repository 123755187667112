document.addEventListener("DOMContentLoaded", function () {
  //if (Cookies.get("notice") == undefined) {
    stepOne.showModal();
  //}

  //$("#stepOne").addClass("")
});

const videoModal = document.getElementById("videoModal");
const watchBtn = document.getElementById("watchBtn");
const videoPlayer = document.getElementById("videoPlayer");
// Function to check if the dialog is open or closed
function isDialogOpen(dialog) {
  return dialog.open;
}

//Function to handle changes in the dialog element
function handleDialogChange(mutationsList) {
  for (const mutation of mutationsList) {
    if (mutation.type === "attributes" && mutation.attributeName === "open") {
      const isOpen = isDialogOpen(mutation.target);
      //console.log("Dialog is " + (isOpen ? "open" : "closed"));
      // Do something based on whether the dialog is open or closed

      if (isOpen) {
        videoPlayer.play();
      } else {
        videoPlayer.pause();
      }
    }
  }
}

//Create a new MutationObserver
const observer = new MutationObserver(handleDialogChange);

observer.observe(videoModal, { attributes: true });

// let dialog = document.querySelectorAll("dialog");
// dialog.forEach((el) =>
//   el.addEventListener("cancel", function (e) {
//     e.preventDefault();
//   }),
// );

const dialogs = document.querySelectorAll("dialog");

function cancelListener(e) {
  e.preventDefault();
}

dialogs.forEach(dialog => {
  dialog.addEventListener("cancel", cancelListener);
});

// When you no longer need the event listeners, remove them
// function removeEventListeners() {
//   dialogs.forEach(dialog => {
//     dialog.removeEventListener("cancel", cancelListener);
//   });
// }


$("#menu-toggle-btn").on("click", function () {
  //$("#toggleDrawer").click()
  //checkCheckboxStatus();
  //disableScroll();

  $("#toggleDrawer").trigger("click");
});

$("#nav li a").on("click", function () {
  //$("#toggleDrawer").click()
  //checkCheckboxStatus();
  //enableScroll();
  $("#toggleDrawer").trigger("click");
});

$("#close-menu").on("click", function () {
  //$("#toggleDrawer").click();
  //checkCheckboxStatus();
  $("#toggleDrawer").trigger("click");
});

$("#toggleDrawer").on("click", function () {
  checkCheckboxStatus();
});

function checkCheckboxStatus() {
  const checkbox = document.getElementById("toggleDrawer");
  const closeMenu = $("body.ltr #close-menu");
  const closeMenuAR = $("body.rtl #close-menu");

  if (checkbox.checked) {
    //console.log('Checkbox is checked');
    closeMenu.addClass("mr-[10vw] xl:mr-[17vw]");
    closeMenuAR.addClass("ml-[10vw] xl:ml-[17vw]");
  } else {
    //console.log('Checkbox is not checked');
    closeMenu.removeClass("mr-[10vw] xl:mr-[17vw]");
    closeMenuAR.removeClass("ml-[10vw] xl:ml-[17vw]");
  }
}

function disableScroll() {
  // Save the current scroll position
  const scrollPosition =
    window.pageYOffset || document.documentElement.scrollTop;

  // Add styles to disable scrolling
  document.body.style.overflow = "hidden";
  document.body.style.position = "fixed";
  document.body.style.top = `-${scrollPosition}px`;
}

function enableScroll() {
  // Restore the scroll position and remove styles
  const scrollPosition = parseInt(document.body.style.top, 10) || 0;
  document.body.style.overflow = "";
  document.body.style.position = "";
  document.body.style.top = "";

  // Scroll back to the original position
  window.scrollTo(0, scrollPosition);
}

//disclaimer

// refresh scroll trigger

let faqDiv = document.getElementById("faq-alpine");
let faqitems = faqDiv.querySelectorAll(".faq-item");

faqitems.forEach((el) =>
  el.addEventListener("click", function (e) {
    setTimeout(function () {
      //console.log("clicked faq:" + new Date());
      ScrollTrigger.refresh();
    }, 400);
  }),
);



// btnDynamic



